import { IMobileRegistryEntry } from "@walletconnect/types";
import { formatIOSMobile, isAndroid } from "@walletconnect/browser-utils";

const formatAndroidMobile = (uri: string, entry: IMobileRegistryEntry) => {
  switch (entry.name) {
    case 'Rainbow':
      const encodedUri: string = encodeURIComponent(uri);
      return `${entry.universalLink}/wc/?uri=${encodedUri}`;
    case 'MetaMask':
      return `${entry.deepLink}${entry.deepLink.endsWith(":") ? "//" : "/"}wc?uri=${uri}`;
    default:
      return '';
  }
}

const pageLinkProcessor = (pageLink: IMobileRegistryEntry, uri: string) => ({
  ...pageLink,
  href: isAndroid() ? formatAndroidMobile(uri, pageLink): formatIOSMobile(uri, pageLink),
  openWalletInNewTab: !isAndroid(),
})

export default pageLinkProcessor;
